import React from "react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { toast } from "@/utils";

type CopyLinkProps = {
    text: string;
};

const CopyLink = ({ text }: CopyLinkProps) => {
    const router = useRouter();

    const handleCopyText = () => {
        const formattedText = text;

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    event: "copy-to-clipboard",
                    payload: formattedText,
                })
            );
        } else {
            navigator.clipboard.writeText(formattedText);
        }

        toast({ type: "info", message: "Copied to clipboard" });
    };

    return (
        <div
            onClick={handleCopyText}
            className="flex min-h-10 w-full cursor-pointer flex-row items-stretch justify-between rounded-md border-0 bg-dark-blue-4"
        >
            <div
                className={`overflow-wrap-anywhere flex items-center p-2 font-poppins text-gray-5 ${
                    router.query.roomID
                        ? "rounded-bl rounded-tl text-xs font-light"
                        : "text-base font-normal"
                }`}
            >
                {text}
            </div>
            <div className="flex h-auto min-w-[26px] items-center justify-center rounded-r-md bg-blue-1 px-3">
                <button
                    aria-label="Copy-link"
                    className="flex items-end justify-end"
                >
                    <DocumentDuplicateIcon className="flex w-6 flex-row justify-end rounded-sm text-white" />
                </button>
            </div>
        </div>
    );
};

export default CopyLink;
