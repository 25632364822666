/* eslint-disable unicorn/consistent-function-scoping */
import React, { Fragment } from "react";
import BottomDialog from "@/components/BottomDialog";
import ROUTES from "@/constants/routes";
import { useRouter } from "next/router";

export type Props = {
    tournamentID: string;
    onClose: () => void;
};

const TournamentEnded = ({ tournamentID, onClose }: Props) => {
    const { push } = useRouter();

    return (
        <BottomDialog open={true} onClose={onClose}>
            <div className="mt-4 flex w-full flex-col gap-y-5 bg-dark-blue-3 pt-1">
                <div
                    className={`relative flex min-h-[180px]  w-full flex-col items-start justify-center rounded-lg border border-selective-yellow border-opacity-50 bg-dark-blue-25 pl-5 shadow-last-move`}
                >
                    <Fragment>
                        <div className="pointer-events-none absolute right-1 top-2 h-full w-16 bg-won-confetti-big bg-contain bg-no-repeat" />
                        <div className="pointer-events-none absolute bottom-1 left-0.5 h-6 w-6 bg-won-confetti-big bg-contain bg-no-repeat" />
                        <div className="item-center ml-1 flex flex-col justify-start gap-y-6">
                            <Fragment>
                                <div className="flex flex-col font-poppins">
                                    <p className="font-bold text-gold-200 text-xl">
                                        Tournament Ended!
                                    </p>
                                </div>
                            </Fragment>
                        </div>
                    </Fragment>
                </div>
                <div className="mt-4 flex w-full items-center justify-center">
                    <button
                        onClick={() =>
                            push(
                                ROUTES.tournamentLeaderboard({
                                    tournamentID,
                                })
                            )
                        }
                        className={`w-full cursor-pointer rounded-md bg-dark-pink-1 py-2 text-center font-bold text-gray-4 outline-none font-basement-grotesque text-lg `}
                        type="button"
                    >
                        Go to leaderboard
                    </button>
                </div>
            </div>
        </BottomDialog>
    );
};

export default TournamentEnded;
