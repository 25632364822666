/* eslint-disable unicorn/filename-case */
import { isClient, isProduction } from "@/utils";

const PRODUCTION_URL = "https://app.ziffichess.com";

const originURL = isClient()
    ? isProduction()
        ? PRODUCTION_URL
        : window.location.origin
    : "http://localhost:3000";

export default originURL;
