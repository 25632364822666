/* eslint-disable max-lines */
import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import CloseIconImg from "@/assets/images/icons/Close.png";
import CopyLink from "@/components/CopyLink";
import Loader from "@/components/Loader";
import originURL from "@/constants/originURL";
import routes from "@/constants/routes";
import { toast } from "@/utils";
import * as Dialog from "@radix-ui/react-dialog";
import Image from "next/image";
import { useRouter } from "next/router";
import TournamentEnded from "../TournamentEnded";
import {
    useAddPlayersToRoomMutation,
    useCreateBattleModeRoomMutation,
    useCreateNewRoomMutation,
    useCreateTournamentRoomMutation,
} from "./operations/operations.generated";

type CreateRoomModalProps = {
    children: React.ReactNode;
    isSinglePlayerGame?: boolean;
    isReplayGame?: boolean;
    tournamentID?: string;
    hasUserMadeAnEntry?: boolean;
    disabled?: boolean;
    className?: string;
    loader?: React.ReactNode;
    shouldWrapInDialog?: boolean;
};

const CreateRoomModal = ({
    children,
    isSinglePlayerGame = false,
    tournamentID,
    className,
    disabled = false,
    loader,
    shouldWrapInDialog = true,
}: CreateRoomModalProps) => {
    const {
        push,
        query: { roomID: roomIDFromURL },
    } = useRouter();

    const { register, watch, handleSubmit } = useForm({
        defaultValues: {
            name: "",
        },
    });

    const [isOpen, setIsOpen] = useState(false);

    const [isBtnClicked, setIsBtnClicked] = useState(false);

    const [isTournamentEnded, setIsTournamentEnded] = useState(false);

    const [createTournamentRoom, { loading: isTournamentRoomGettingCreated }] =
        useCreateTournamentRoomMutation({
            onCompleted: (data) => {
                if (data?.createNewRoom?.id) {
                    push(
                        routes.room({
                            roomID: data?.createNewRoom?.id,
                        })
                    );
                }
            },
            onError: (error) => {
                console.log({
                    error,
                });

                if (error?.message?.includes("Tournament has ended"))
                    setIsTournamentEnded(true);
            },
        });

    const [createNewBattleModeRoom, { data, loading }] =
        useCreateBattleModeRoomMutation({
            onError: (error) => {
                console.log({
                    error,
                });
            },
        });

    const [createNewRoom, { data: createNewRoomData }] =
        useCreateNewRoomMutation({
            onError: (error) => {
                console.log({
                    error,
                });
            },
        });

    const roomID =
        data?.createBattleModeRoom?.id ||
        createNewRoomData?.insert_rooms_one?.id;

    const [addPlayersToRoom, { loading: isParticipantAdding }] =
        useAddPlayersToRoomMutation({
            onCompleted: (data) => {
                if (data?.insert_room_participants?.affected_rows) {
                    // setIsOpen(false);
                    // setIsBtnClicked(false);
                    push(
                        routes.room({
                            roomID: roomID || roomIDFromURL,
                        })
                    );
                }
            },
        });

    useEffect(() => {
        if (roomID || roomIDFromURL) {
            if (isSinglePlayerGame) {
                push(
                    routes.room({
                        roomID: roomID || roomIDFromURL,
                    })
                );
            } else setIsOpen(true);
        }
    }, [roomID, roomIDFromURL]);

    const createRoom = () => {
        if (!isSinglePlayerGame) {
            createNewRoom({
                variables: {
                    is_single_player_game: isSinglePlayerGame,
                },
            });

            return;
        }

        createNewBattleModeRoom();
    };

    const openModal = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        if (tournamentID && isSinglePlayerGame) {
            createTournamentRoom({
                variables: {
                    tournamentID,
                    isSinglePlayer: isSinglePlayerGame,
                },
            });
        } else {
            createRoom();
        }
    };

    const name = watch("name");

    const onSubmit: Parameters<typeof handleSubmit>[0] = (data) => {
        setIsBtnClicked(true);

        const participantsData = [
            {
                room_id: roomID || roomIDFromURL,
                name: data.name,
            },
        ];

        addPlayersToRoom({
            variables: {
                participantsData,
            },
        });
    };

    const isJoin = !!roomIDFromURL;

    return (
        <Fragment>
            {isTournamentEnded && tournamentID && (
                <TournamentEnded
                    tournamentID={tournamentID}
                    onClose={() => {
                        setIsTournamentEnded(false);
                    }}
                />
            )}
            {shouldWrapInDialog ? (
                <Dialog.Root open={isOpen}>
                    <Dialog.Trigger asChild>
                        <div
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault();
                                    // if (handleClick) handleClick();
                                } else {
                                    openModal(e);
                                }
                            }}
                            className={`w-auto ${className}`}
                        >
                            {loading ||
                            isParticipantAdding ||
                            isTournamentRoomGettingCreated ||
                            (!!roomID && (isOpen || isSinglePlayerGame)) ? (
                                <Fragment>
                                    {loader ?? (
                                        <button
                                            data-testid="start-new-game-button"
                                            className={`rounded-md bg-dark-pink-1 px-[22px] py-1 text-center font-black tracking-wider text-gray-4 font-basement-grotesque text-base ${
                                                isTournamentRoomGettingCreated
                                                    ? "w-full !py-2.5"
                                                    : ""
                                            }`}
                                        >
                                            <Loader className="!my-0 !h-5 !w-5 border-white" />
                                        </button>
                                    )}
                                </Fragment>
                            ) : (
                                children
                            )}
                        </div>
                    </Dialog.Trigger>
                    <Dialog.Portal>
                        <Dialog.Overlay />
                        <div className="fixed bottom-0 left-0 right-0 top-0 z-50 mx-auto w-full max-w-screen-w bg-modal backdrop-blur-sm">
                            <div className="absolute bottom-0 left-0 right-0">
                                <div className="pointer-events-auto mb-4 flex items-center justify-center">
                                    <Dialog.Close asChild>
                                        <button
                                            aria-label="Close"
                                            onClick={() => {
                                                setIsOpen(false);
                                                if (isJoin) push("/");
                                            }}
                                        >
                                            <Image
                                                src={CloseIconImg}
                                                alt="close-image"
                                                height={40}
                                                width={40}
                                                draggable={false}
                                                className="cursor-pointer"
                                            />
                                        </button>
                                    </Dialog.Close>
                                </div>
                                <Dialog.Content className="DialogContent w-full rounded-t-5xl bg-dark-blue-3 p-6">
                                    {!isSinglePlayerGame && (
                                        <Fragment>
                                            <Dialog.Title
                                                className={`DialogTitle mb-8 flex items-center justify-center font-bold text-gray-4 font-basement-grotesque text-lg`}
                                            >
                                                {isJoin
                                                    ? "Join Game"
                                                    : "Create Game"}
                                            </Dialog.Title>
                                            <div className="flex w-full items-center justify-center text-gray-5">
                                                {(isOpen || isJoin) && (
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            onSubmit
                                                        )}
                                                        className="w-full"
                                                    >
                                                        {!isJoin && (
                                                            <div
                                                                className="mb-6 flex w-full flex-col items-center justify-center"
                                                                data-testid="send-your-opponent-this-link"
                                                            >
                                                                <p className="mb-2 w-full font-normal text-gray-5 font-poppins text-sm">
                                                                    Send your
                                                                    opponent
                                                                    this link:
                                                                </p>
                                                                <CopyLink
                                                                    text={`${originURL}/room/${roomID}`}
                                                                />
                                                            </div>
                                                        )}

                                                        <div className="mb-6 flex w-full flex-col items-center justify-center">
                                                            <p className="mb-2 w-full font-normal text-gray-5 font-poppins text-sm">
                                                                Type your name:
                                                            </p>
                                                            <input
                                                                autoComplete="name"
                                                                type="text"
                                                                data-testid="name-input"
                                                                className="h-10 w-full rounded-md border-0 bg-dark-blue-4 p-2 font-normal text-gray-5 font-poppins text-base focus:outline-0"
                                                                {...register(
                                                                    "name",
                                                                    {
                                                                        required:
                                                                            true,
                                                                    }
                                                                )}
                                                            />
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    !isSinglePlayerGame &&
                                                                    name === ""
                                                                ) {
                                                                    toast({
                                                                        type: "error",
                                                                        message:
                                                                            "Please enter your name",
                                                                    });

                                                                    return;
                                                                }
                                                            }}
                                                            className={`mt-2 h-11 w-full cursor-pointer rounded-md bg-dark-pink-1 text-center font-bold text-gray-4 font-basement-grotesque text-lg ${
                                                                name
                                                                    ? ""
                                                                    : "opacity-50"
                                                            }`}
                                                            type="submit"
                                                        >
                                                            {isBtnClicked ||
                                                            isParticipantAdding ? (
                                                                <Loader className="!my-0 border-white" />
                                                            ) : isJoin ? (
                                                                "Enter game room"
                                                            ) : (
                                                                "Start game room"
                                                            )}
                                                        </button>
                                                    </form>
                                                )}
                                            </div>
                                        </Fragment>
                                    )}
                                </Dialog.Content>
                            </div>
                        </div>
                    </Dialog.Portal>
                </Dialog.Root>
            ) : (
                <div className={className} onClick={openModal}>
                    {children}
                </div>
            )}
        </Fragment>
    );
};

export default CreateRoomModal;
