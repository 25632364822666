//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type CreateTournamentRoomMutationVariables = Types.Exact<{
  tournamentID: Types.Scalars['uuid'];
  isSinglePlayer: Types.Scalars['Boolean'];
}>;


export type CreateTournamentRoomMutation = { __typename?: 'Mutation', createNewRoom: { __typename?: 'rooms', id?: any | null } };

export type CreateSinglePlayerRoomMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateSinglePlayerRoomMutation = { __typename?: 'Mutation', insert_rooms_one?: { __typename?: 'rooms', id?: any | null } | null };

export type CreateNewRoomMutationVariables = Types.Exact<{
  is_single_player_game: Types.Scalars['Boolean'];
}>;


export type CreateNewRoomMutation = { __typename?: 'Mutation', insert_rooms_one?: { __typename?: 'rooms', id?: any | null } | null };

export type CreateBattleModeRoomMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateBattleModeRoomMutation = { __typename?: 'Mutation', createBattleModeRoom: { __typename?: 'rooms', id?: any | null } };

export type AddPlayersToRoomMutationVariables = Types.Exact<{
  participantsData: Array<Types.Room_Participants_Insert_Input> | Types.Room_Participants_Insert_Input;
}>;


export type AddPlayersToRoomMutation = { __typename?: 'Mutation', insert_room_participants?: { __typename?: 'room_participants_mutation_response', affected_rows: number } | null };


export const CreateTournamentRoomDocument = gql`
    mutation CreateTournamentRoom($tournamentID: uuid!, $isSinglePlayer: Boolean!) {
  createNewRoom(tournamentID: $tournamentID, isSinglePlayer: $isSinglePlayer) {
    id
  }
}
    `;
export type CreateTournamentRoomMutationFn = Apollo.MutationFunction<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;

/**
 * __useCreateTournamentRoomMutation__
 *
 * To run a mutation, you first call `useCreateTournamentRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentRoomMutation, { data, loading, error }] = useCreateTournamentRoomMutation({
 *   variables: {
 *      tournamentID: // value for 'tournamentID'
 *      isSinglePlayer: // value for 'isSinglePlayer'
 *   },
 * });
 */
export function useCreateTournamentRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>(CreateTournamentRoomDocument, options);
      }
export type CreateTournamentRoomMutationHookResult = ReturnType<typeof useCreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationResult = Apollo.MutationResult<CreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationOptions = Apollo.BaseMutationOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;
export const CreateSinglePlayerRoomDocument = gql`
    mutation CreateSinglePlayerRoom {
  insert_rooms_one(object: {is_single_player_game: true}) {
    id
  }
}
    `;
export type CreateSinglePlayerRoomMutationFn = Apollo.MutationFunction<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>;

/**
 * __useCreateSinglePlayerRoomMutation__
 *
 * To run a mutation, you first call `useCreateSinglePlayerRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSinglePlayerRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSinglePlayerRoomMutation, { data, loading, error }] = useCreateSinglePlayerRoomMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSinglePlayerRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>(CreateSinglePlayerRoomDocument, options);
      }
export type CreateSinglePlayerRoomMutationHookResult = ReturnType<typeof useCreateSinglePlayerRoomMutation>;
export type CreateSinglePlayerRoomMutationResult = Apollo.MutationResult<CreateSinglePlayerRoomMutation>;
export type CreateSinglePlayerRoomMutationOptions = Apollo.BaseMutationOptions<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>;
export const CreateNewRoomDocument = gql`
    mutation CreateNewRoom($is_single_player_game: Boolean!) {
  insert_rooms_one(object: {is_single_player_game: $is_single_player_game}) {
    id
  }
}
    `;
export type CreateNewRoomMutationFn = Apollo.MutationFunction<CreateNewRoomMutation, CreateNewRoomMutationVariables>;

/**
 * __useCreateNewRoomMutation__
 *
 * To run a mutation, you first call `useCreateNewRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewRoomMutation, { data, loading, error }] = useCreateNewRoomMutation({
 *   variables: {
 *      is_single_player_game: // value for 'is_single_player_game'
 *   },
 * });
 */
export function useCreateNewRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewRoomMutation, CreateNewRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateNewRoomMutation, CreateNewRoomMutationVariables>(CreateNewRoomDocument, options);
      }
export type CreateNewRoomMutationHookResult = ReturnType<typeof useCreateNewRoomMutation>;
export type CreateNewRoomMutationResult = Apollo.MutationResult<CreateNewRoomMutation>;
export type CreateNewRoomMutationOptions = Apollo.BaseMutationOptions<CreateNewRoomMutation, CreateNewRoomMutationVariables>;
export const CreateBattleModeRoomDocument = gql`
    mutation CreateBattleModeRoom {
  createBattleModeRoom {
    id
  }
}
    `;
export type CreateBattleModeRoomMutationFn = Apollo.MutationFunction<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>;

/**
 * __useCreateBattleModeRoomMutation__
 *
 * To run a mutation, you first call `useCreateBattleModeRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBattleModeRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBattleModeRoomMutation, { data, loading, error }] = useCreateBattleModeRoomMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBattleModeRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>(CreateBattleModeRoomDocument, options);
      }
export type CreateBattleModeRoomMutationHookResult = ReturnType<typeof useCreateBattleModeRoomMutation>;
export type CreateBattleModeRoomMutationResult = Apollo.MutationResult<CreateBattleModeRoomMutation>;
export type CreateBattleModeRoomMutationOptions = Apollo.BaseMutationOptions<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>;
export const AddPlayersToRoomDocument = gql`
    mutation AddPlayersToRoom($participantsData: [room_participants_insert_input!]!) {
  insert_room_participants(
    objects: $participantsData
    on_conflict: {constraint: room_participants_room_id_player_id_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type AddPlayersToRoomMutationFn = Apollo.MutationFunction<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>;

/**
 * __useAddPlayersToRoomMutation__
 *
 * To run a mutation, you first call `useAddPlayersToRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayersToRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayersToRoomMutation, { data, loading, error }] = useAddPlayersToRoomMutation({
 *   variables: {
 *      participantsData: // value for 'participantsData'
 *   },
 * });
 */
export function useAddPlayersToRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>(AddPlayersToRoomDocument, options);
      }
export type AddPlayersToRoomMutationHookResult = ReturnType<typeof useAddPlayersToRoomMutation>;
export type AddPlayersToRoomMutationResult = Apollo.MutationResult<AddPlayersToRoomMutation>;
export type AddPlayersToRoomMutationOptions = Apollo.BaseMutationOptions<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>;